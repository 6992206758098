import React, { useEffect, useRef, useState } from "react";
import { graphql, Link } from "gatsby";
import { mapEdgesToNodes, filterOutDocsWithoutSlugs } from "../lib/helpers";
import Seo from "../components/SEO/SEO"
import Image from "gatsby-plugin-sanity-image";
import Footer from "../components/footer"
import ReactPlayer from 'react-player'


// import Swiper core and required modules
import { Lazy, Navigation, Pagination, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';

const SLIDE_AVAILABLE_OFFSET = 150;

export const query = graphql`
  query ProjectPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      defaultImage {
        asset {
          _id
        }
      }
    }
    projects: allSanityProject(
      sort: { fields: [order], order: ASC }
      filter: { slug: { current: { ne: null } } }
    ) {
      edges {
        node {
          id
          previewImage {
            ...Image
          }
      slideshow {
        images {
          __typename
          ... on SanityImage {
            ...Image
          }
          ... on SanityVideo {
            _id
            videoFile {
              asset {
                  url
                }
            }
          }
        }
      }
          title
          photographerUrl
          photographer
          location
          architects
          architectsUrl
          completed
          slug {
            current
          }
          projectType {
            id
            title
          }
        }
      }
    }
    categories: allSanityCategory(
      sort: { fields: [order], order: ASC }
      filter: { slug: { current: { ne: null } } }
    ) {
      edges {
        node {
          title
          id
        }
      }
    }
    sanityProjects {
      description
    }
  }
`;

const Projects = (props) => {

  const { data } = props
  const site = (data || {}).site
  const [projects, setProjects] = useState([]);
  const [hideProjects, setHideProjects] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [open, setOpen] = useState(false);
  // const [ testisplaying, setTestisplaying ] = useState(false)

  const [isCommercial, setIsCommercial] = useState(false);
  useEffect(() => setIsCommercial(document.querySelector('button[data-name="Commercial"]').classList.contains('filter-active')) );

  const projectNodes = (data || {}).projects
    ? mapEdgesToNodes(data.projects).filter(filterOutDocsWithoutSlugs)
    : [];

  const categoryNodes =
    data && data.categories && mapEdgesToNodes(data.categories);

  useEffect(() => {
    setProjects(projectNodes);
  }, []);

  function filterProjects(filter) {
    setSelectedFilter(filter);
    setHideProjects(true);
    setTimeout(() => {
      if (filter) {
        setProjects(
          projectNodes.filter((el) =>
            el.projectType.map((el) => el.id).includes(filter)
          )
        );
      } else {
        setProjects(projectNodes);
      }
      setHideProjects(false);
    });
  }

  function ProjectListing(props) {
    return (
      <>
        {props.nodes &&
        props.nodes
        .map((node) => (
          <div
            className="overflow-hidden project group uppercase text-[15px] tracking-[0.3em] text-white relative"
            key={node.id}
          >
            <ProjectPreview {...node} />
          </div>
        ))}
      </>
    );
  }

  ProjectListing.defaultProps = {
    title: "",
    nodes: [],
  };


  const ProjectSlide = ({ item }) => {
    if (item.__typename === 'SanityImage') {
      return (
        <Image
          {...item}
          width={800}
          height={600}
          className="swiper-lazy"
          alt=" "
        />
      );
    } else if (item.__typename === 'SanityVideo') {
      return (
        <ReactPlayer 
          url={item.videoFile.asset.url}
          width="100%"
          height="100%"
          className="aspect-[800/600] relative video-wrapper"
          playing
          playsinline={true}
          loop
          muted
        />
      );
    } else {
      return null;
    }
  };

  const pagination = {
    clickable: true 
  };

  function useSwiperAutoplayVideos(swiper) {
    // console.log("auto video player");
  useEffect(() => {
    if (!swiper) return;

    const playCurrentVideo = () => {
      const currentSlide = swiper.slides[swiper.activeIndex];
      if (!currentSlide) return;

      const player = currentSlide.querySelector('.react-player>video');
      if (player && player.play) {
        const coord = player.getBoundingClientRect();
        if(!(coord.top + SLIDE_AVAILABLE_OFFSET > window.innerHeight || coord.top + coord.height < SLIDE_AVAILABLE_OFFSET)){
          player.play();
        }
      }
    };

    const pauseAllVideos = () => {
      swiper.slides.forEach((slide) => {
        const player = slide.querySelector('.react-player>video');
        if (player && player.pause) {
          player.pause();
        }
      });
    };

    playCurrentVideo();

    swiper.on('slideChange', () => {
      pauseAllVideos();
      playCurrentVideo();
    });

    return () => {
      swiper.off('slideChange');
    };
  }, [swiper]);
}

  function ProjectPreview(props) {
    const [open, setOpen] = React.useState(false);
    const [swiper, setSwiper] = useState(null);
    
    useSwiperAutoplayVideos(swiper);

    useEffect(() => {
      const handleScroll = () => {
        if(swiper) {
        const videoElement = swiper.slides[swiper?.activeIndex]?.querySelector('.react-player>video');
        if(videoElement) {
          const coord = videoElement.getBoundingClientRect();
          if(coord.top + SLIDE_AVAILABLE_OFFSET > window.innerHeight || coord.top + coord.height < SLIDE_AVAILABLE_OFFSET) {
            videoElement.pause();
          } else {
            videoElement.play();
          }
        }
        }
      }
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }, [swiper])

    return (
      <>
      {props.slideshow &&
        <Swiper
          modules={[Lazy, Navigation, A11y, Pagination]}
          navigation={true}
          slidesPerView={1}
          lazy={true}
          loop={true}
          onSwiper={setSwiper}
          pagination={pagination}
          speed={600}
        >
          {props.slideshow.images.map((item, index) => (
            <SwiperSlide key={index}>
              <ProjectSlide item={item} isplaying={false} />
            </SwiperSlide>
          ))}
        </Swiper>
      }
        <div className="navigation absolute top-0 left-0 p-6 gap-4 flex z-10 opacity-0 transition-opacity ease-in-out duration-200 group-hover:opacity-100">
          <button
            className="uppercase tracking-[0.3em]"
            onClick={() => {
              setOpen(!open);
            }}
          >Info</button>
          <Link 
            className="hidden lg:flex"
            to={`/project/${props.slug.current}`}
            state={{
              modal: true
            }}
          >
            Expand
          </Link>
          {/* {props.mediaUrl && 
            <a href={props.mediaUrl} target="_blank" rel="noopener noreferrer">
                Media
            </a>
          } */}
        </div>
        <div className={open ? `overlay z-10 absolute top-0 left-0 w-full h-full p-6 ${props.projectType[0].title}` : `overlay hidden z-10 absolute top-0 left-0 w-full h-full p-6 ${props.projectType[0].title}`}>
          <button
            className="uppercase tracking-[0.3em]"
            onClick={() => {
              setOpen(!open);
            }}
          >Close</button>
          <div className="flex mt-6">
            <div className="w-1/2">
              {props.projectType && 
                <h2>{props.projectType[0].title}</h2>
              }
            </div>
            <div className="w-1/2">
              {props.title && 
                <h2>{props.title}</h2>
              }
              {props.location && 
                <h2 className="mt-6">{props.location}</h2>
              }
              {props.completed && 
                <h2 className="mt-1">Completed {props.completed}</h2>
              }
              {props.architects && 
                <h2 className="mt-1">{props.architects}</h2>
              }
              {props.photographer && 
                <h2 className="mt-1">Photos. {props.photographer}</h2>
              }
            </div>
          </div>
        </div>
      </>
    );
  }

  function DesktopCategoryListing(props) {
    return (
      <>
        {props.nodes &&
          props.nodes.map((node) => (
            <li className="my-px" key={node.id}>
              <DesktopCategory {...node} />
            </li>
          ))}
      </>
    );
  }

  DesktopCategoryListing.defaultProps = {
    title: "",
    nodes: [],
  };

  function DesktopCategory(props) {
    return (
      <button
        onClick={() => {
          filterProjects(props.id);
        }}
        className={selectedFilter === props.id ? " text-white filter-active px-3 md:px-6" : " px-3 md:px-6"}
        data-name={props.title}
      >
        {props.title}
      </button>
    );
  }

  return (
    <>
      <Seo
        title={`Projects`}
        description={data.sanityProjects.description}
        image={site.defaultImage}
      />
      <header className="container z-10">
        <div className={isCommercial ? "flex lg:grid lg:grid-cols-2 gap-4 pt-6 lg:pt-16 lg:pb-[5px] lg:gap-16 items-start text-[#F05F17]" : "flex lg:grid lg:grid-cols-2 gap-4 pt-6 lg:pt-16 lg:pb-[5px] lg:gap-16 items-start text-[#1C6DB5]"}>
          <Link className={isCommercial ? "logo logo-orange" : "logo"} to="/"><span className="sr-only">Dimpat</span></Link>
          <button
            className="ml-auto flex lg:hidden uppercase -mt-[6px] tracking-[0.3em]"
            onClick={() => {
              setOpen(!open);
            }}
          >Menu</button>
          <nav className="hidden lg:flex lg:ml-auto gap-4 lg:gap-8 uppercase text-[15px] tracking-[0.1em] lg:tracking-[0.3em]">
            <Link to="/projects">Projects</Link>
            <Link to="/about-us">About us</Link>
            <Link to="/get-in-touch">Get in touch</Link>
          </nav>
        </div>
        <span className={isCommercial ? "w-full h-[2px] bg-[#F05F17] inline-block" : "w-full h-[2px] bg-[#1C6DB5] inline-block"}></span>
      </header>
      <div className={open ? `fixed bg-[#1C6DB5] text-white top-0 left-0 w-screen h-screen lg:hidden z-20` : `fixed invisible bg-[#1C6DB5] text-white top-0 left-0 w-screen h-screen lg:hidden z-20`}>
        <div className="p-6 flex flex-col relative h-full">
          <button
              className="uppercase absolute right-4 -mt-[6px] tracking-[0.3em]"
              onClick={() => {
                setOpen(!open);
              }}
            >Close</button>
          <ul className="mt-16 w-screen uppercase text-[25px] tracking-[0.1em] mobile">
            <li className="mb-5"><Link className="mb-5 inline-block" to="/projects">Projects</Link></li>
            <li className="mb-5"><Link className="mb-5 inline-block" to="/about-us">About us</Link></li>
            <li className="mb-5"><Link to="/get-in-touch">Get in touch</Link></li>
          </ul>
          <ul className="mt-auto flex socials gap-8 uppercase text-[15px] tracking-[0.3em]">
            <li>
              <a href="https://www.instagram.com/dimpat__/" target="_blank" rel="noreferrer">Instagram</a>
            </li>
            <li>
              <a href="https://www.instagram.com/dimpat__/" target="_blank" rel="noreferrer">Facebook</a>
            </li>
          </ul>
        </div>
      </div>
      <main>
        <div className="container mt-6 md:mt-24">
          <div className="grid grid-cols-1 items-baseline gap-4 lg:gap-14 mb-8 lg:mb-16">
            <h1 className="text-[24px] leading-[50px] lg:text-[35px] lg:leading-[80px]">
              Projects
            </h1>
            <ul className="leading-[35px] md:text-[23px] md:leading-[55px] flex flex-row gap-4 md:gap-8">
              <li className="mb-px">
                <button
                  onClick={() => filterProjects(null)}
                  className={
                    selectedFilter === null ? "active bg-[#1C6DB5] text-white px-3 md:px-6" : " px-3 md:px-6"
                  }
                >
                  All
                </button>
              </li>
              {categoryNodes && (
                <DesktopCategoryListing nodes={categoryNodes} />
              )}
            </ul>
          </div>
        </div>
        <div
          className={`project-listing container grid grid-cols-1 gap-4 lg:gap-14 lg:grid-cols-2 ${
            hideProjects ? "hide" : ""
          }`}
        >
          <ProjectListing nodes={projects} />
        </div>
      </main>
      <Footer />
    </>
  )
  
}

export default Projects
